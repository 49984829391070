<template>
  <div class="bg d-flex justify-content-center" @click.self="close" :class="{'no-display': !open}"> 

      <div class="document iframe" style="background:white">
          <div class="topbar">
            
              <button class="btn btn-primary btn-close" @click="close" >
                <i class="fa fa-times"></i>
            </button>
          </div>
        <div>
            <SpinOverlay :active="true" styles="background:#ffffff6e, z-index:0; margin-top:100px" />
          </div>
       <div style="z-index:99; top: 0; position: absolute">
         <iframe v-if="document.src" id="docPreview" seamless="true" :src="`${envVariables.VUE_APP_STORAGE_BUCKET}${document.src}#toolbar=0`" class="iframe" style="height:75vh;" frameborder="0" onload="loading">
       </iframe>
       </div>
          
       <!-- <embed :src="`${envVariables.VUE_APP_STORAGE_BUCKET}${document.src}`" type="application/pdf" width="100%" height="100%"> -->

        <div class=" toolbar d-flex justify-content-center" :class="{'no-display': document.status}">
          <div class="action-btn mx-2">
            <a href="#!" class="btn btn-success btn-sm btn-default btn-squared btn-success" @click="fileDocument(1)">
                <i class="la la-check"></i> Approve</a>
        </div>
          <div class="action-btn mx-2"  data-toggle="modal" data-target="#rejectionModal">
            <a href="#!" class="btn btn-danger btn-default btn-sm btn-squared btn-shadow-danger">
                <i class="la la-times"></i> Reject </a>
        </div>
          
        </div>
      </div>
      <div class="modal fade" id="rejectionModal" tabindex="-1" role="dialog" aria-labelledby="rejectionModalTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Rejection Reason</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="form-group mb-0">
            <textarea v-model="note" class="form-control" id="rejectionReasons" rows="5" style="resize: none; border:0"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" @click="fileDocument(-1)" data-dismiss="modal">Save changes</button>
      </div>
    </div>
  </div>
</div>
      
  </div>
</template>

<script>
import { apiClient } from '@/plugins/restclient';
import { getTimestamp } from "@/utilities";
import SpinOverlay from './SpinOverlay.vue';
// import jQ from "jquery";
export default {
  components: { SpinOverlay },
    layout:'plain',
    data(){
        return{
            open: false,
            docPath:'',
            note: '',
            tag: ''
        }
    },
    props:['document'],
    watch:{
      document(){
        this.open = true
      }
    },
    created(){
      // jQ('#docPreview').on('load', () => {
      //   console.log('iframe is completely loaded');
      // })
    },
    methods:{
      close(){
        this.open = false
        this.$emit('documentClose')
      },
      preview(){
        this.docPath = this.document.src
        document.querySelector("iframe").addEventListener( "load", function() {

          this.style.backgroundColor = "red";

      } );
      },
      fileDocument(status){
        this.$store.commit('loader', true)
        apiClient.patch('/v1/office/account/document/approve',
        {
          dolid: this.document.dolid,
          tag: this.document.tag,
          note: '',
          status: status
        },
        {
          headers:{
              "X-Timestamp": getTimestamp(),
              "X-Ulid": `${this.$store.state.user.ulid}`,
          }
        }
        )
        .then(res =>{
          this.$emit('docStatus', {
            document: this.document,
            status
            }
          )
          // console.log('docstatus sucess')
          this.$store.commit('loader', false)
          this.$store.commit('ctoast', {message: res.data.message, type: 'success', timeout: 30000})
          this.open = false
        })
        .catch(err => {
          this.$store.commit('loader', false)
          this.$store.commit('ctoast', {message: err.response.data.message, type: 'danger', timeout: 30000})
          this.open = false
        })
      }
    }
}
</script>

<style>
.bg{
    background: #4e4d4d9e;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9999;
    width: 100%;

}
.topbar{
    text-align: right;
}
.btn-close{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 10px;
    background: #323639;
    border: 0px;
}
.btn-close i{
    margin: 0px;
}
.document{
    position: relative;
    /* padding: 30px; */
    height: 80vh;
    margin-top: 30px;
    background:#585653;
    z-index: 99;
    /* overflow: hidden; */
}
.no-display{
    display: none !important;
}
.toolbar{
    position: absolute;
    width: 100%;
    bottom: -100px;
    z-index: 999;
}
.iframe{
  width: 800px;
}
@media only screen and (max-width: 600px) {
  .iframe{
    width:100vw !important
  }
}
</style>